import styled from 'styled-components';
import React, { useState } from 'react';
import {
  Container,
  Box,
  Typography,
  Button,
  Menu,
  MenuItem,
} from '@mui/material';
import '@fontsource/bona-nova';
import { ThemeProvider, createTheme } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { ReactComponent as HeaderSvg } from './assets/disco_art.svg';

export const StyledPageContainer = styled(Box)`
  min-height: 400px;
  padding-bottom: 50px;
  padding-top: 100px;
`;

export const Header = styled(Box)`
  color: #fff;
  display: flex;
  justify-content: space-around;
  backdrop-filter: blur(8px);
  position: fixed;
  width: 100%;
`;

const HotelImg = styled.img`
  width: 300px;
  -webkit-box-shadow: 7px 7px #dc7e8b;
  box-shadow: 7px 7px #dc7e8b;
`;

const ParkingImg = styled.img`
  width: 90%;
  max-width: 800px;
  margin: 1em 1em;
  -webkit-box-shadow: 7px 7px #dc7e8b;
  box-shadow: 7px 7px #dc7e8b;
`;

const DcFunImg = styled.img`
  width: 200px;
`;

const DcFunBox = styled(Box)`
  width: 250px;
  text-align: center;
  margin-bottom: 4em;
  text-decoration: none;
`;

const RegistryImg = styled.img`
  width: 200px;
`;

const RegistryTile = styled(Box)`
  width: 250px;
  height: 250px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 3em;
  border-radius: 50px;
  -webkit-box-shadow: 10px 10px #a35442;
  box-shadow: 10px 10px #a35442;
`;

const HotelBox = styled(Box)`
  text-align: center;
`;

const RegistryContainer = styled(Box)`
  display: flex;
  margin: 60px 0;
  justify-content: space-evenly;
  flex-wrap: wrap;
`;

const DetailsBox = styled(Box)`
  display: flex;
  justify-content: center;
  padding: 2em;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2em;
  flex-wrap: wrap;
`;

export const StyledButton = styled(Button)``;

const DetailsBox1 = styled(Box)`
  display: flex;
  justify-content: space-evenly;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2em;
  align-items: stretch;
  flex-wrap: wrap;
  margin-bottom: 2em;
`;

const COPImg = styled.img`
  width: 250px;
`;

export const Footer = styled.footer`
  text-align: center;
  color: #fff;
  background: #dfbfb4;
  width: 100%;
  position: static;
  bottom: 20px;
  font-weight: 700 !important;
  left: 0;
  height: 100px;
`;

const SvgContainer = styled(Box)`
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
`;

export const StyledBox = styled(Box)`
  margin-top: 10px;
  min-width: 70%;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #517572;
`;

export const Subtitle = styled(Typography)`
  font-family: 'Roboto Slab', serif !important;
  src: local('Roboto Slab'),
    url(./fonts/RobotoSlab-Light.ttf) format('truetype');
`;

export const StyledTitle = styled(Typography)`
  font-family: 'Alstoria-Regular', serif !important;
  src: local('Alstoria-Regular'),
    url(./fonts/AlstoriaDemo.ttf) format('truetype');
  text-align: center;
`;

export const StyledMenu = styled(Menu)``;

export const StyledMenuItem = styled(MenuItem)`
  text-decoration: none !important;
  > a {
    text-decoration: none !important;
    color: #517572;
  }
`;

export const NavButton = styled(Button)`
  margin: 0.5em 0.5em !important;
  color: #517572 !important;
  font-family: 'Roboto Slab', serif !important;
  src: local('Roboto Slab'),
    url(./fonts/RobotoSlab-Light.ttf) format('truetype');
  border-color: #fff !important;
  font-size: 16px !important;
`;

const DcThings = [
  {
    title: "Sonny's Pizza",
    img: 'SonnysPizza.png',
    nb: 'Park View',
    url: 'https://www.sonnyspizzadc.com/',
  },
  {
    title: 'Elle',
    img: 'Elle.png',
    nb: 'Mount Pleasant',
    url: 'https://www.eatatelle.com/',
  },
  {
    title: 'Call Your Mother',
    img: 'CallYourMother.png',
    nb: 'Park View, Capitol Hill, Logan Circle',
    url: 'https://www.callyourmotherdeli.com/',
  },
  {
    title: 'Compliments Only',
    img: 'ComplimentsOnly.png',
    nb: 'Dupont Circle',
    url: 'https://www.complimentsonlysubs.com/',
  },
  {
    title: "Kaldi's Social House",
    img: 'Kaldi.png',
    nb: 'Arlington',
    url: 'https://www.kaldissocialhouse.net/',
  },
  {
    title: 'Lucky Buns',
    img: 'LuckyBuns.png',
    nb: 'Adams Morgan, Union Market',
    url: 'https://www.luckybuns.com/',
  },
  {
    title: 'Other Half Brewing',
    img: 'OtherHalf.png',
    nb: 'Ivy City',
    url: 'https://otherhalfbrewing.com/location/washington-dc/',
  },
  {
    title: 'Falafel, Inc.',
    img: 'FalafelInc.png',
    nb: 'Georgetown, Wharf',
    url: 'https://www.falafelinc.org/',
  },
];

const theme = createTheme({
  typography: {
    fontFamily: 'Roboto Slab',
  },
});

const App = () => {
  // const [rsvpDisplay, setRsvpDisplay] = useState(false);
  // const handleChange = (e) => {
  //   if (e.target.value.toLowerCase() === '101422') {
  //     setRsvpDisplay(true);
  //   }
  // };
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <ThemeProvider theme={theme}>
      <Header sx={{ justifyContent: { xs: 'flex-end', md: 'space-around' } }}>
        <StyledBox sx={{ display: { xs: 'none', md: 'flex' } }}>
          <NavButton variant='text' href='/#home'>
            HOME
          </NavButton>
          <NavButton variant='text' href='/#travel'>
            TRAVEL
          </NavButton>
          <NavButton variant='text' href='/#things-to-do'>
            THINGS TO DO
          </NavButton>
          <NavButton variant='text' href='/#rsvp'>
            RSVP
          </NavButton>
          <NavButton variant='text' href='/#registry'>
            REGISTRY
          </NavButton>
          <NavButton href="/seating">
            SEATING
          </NavButton>
        </StyledBox>
        <StyledButton
          sx={{
            display: { md: 'none' },
            marginTop: '20px',
            marginRight: '1em',
          }}
          aria-controls={open ? 'demo-customized-menu' : undefined}
          aria-haspopup='true'
          aria-expanded={open ? 'true' : undefined}
          disableElevation
          onClick={handleClick}
        >
          <MenuIcon sx={{ color: '#fff', fontSize: '56px' }} />
        </StyledButton>
        <StyledMenu
          MenuListProps={{
            'aria-labelledby': 'demo-customized-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          <StyledMenuItem onClick={handleClose}>
            <a href='/#home'>
              <Typography variant='body1'>HOME</Typography>
            </a>
          </StyledMenuItem>
          <StyledMenuItem onClick={handleClose}>
            <a href='/#travel'>
              <Typography variant='body1'>TRAVEL</Typography>
            </a>
          </StyledMenuItem>
          <StyledMenuItem onClick={handleClose}>
            <a href='/#things-to-do'>
              <Typography variant='body1'>THINGS TO DO</Typography>
            </a>
          </StyledMenuItem>
          <StyledMenuItem onClick={handleClose}>
            <a href='/#rsvp'>
              <Typography variant='body1'>RSVP</Typography>
            </a>
          </StyledMenuItem>
          <StyledMenuItem onClick={handleClose}>
            <a href='/#registry'>
              <Typography variant='body1'>REGISTRY</Typography>
            </a>
          </StyledMenuItem>
          <StyledMenuItem onClick={handleClose}>
            <a href='/seating'>
              <Typography variant='body1'>SEATING</Typography>
            </a>
          </StyledMenuItem>
        </StyledMenu>
      </Header>
      <StyledPageContainer
        sx={{
          background: '#bacfc2',
        }}
        id='home'
      >
        <Container>
          <SvgContainer>
            <HeaderSvg />
          </SvgContainer>

          <Subtitle variant='h4' sx={{ color: '#517572', textAlign: 'center' }}>
            October 14, 2022 | Washington, DC
          </Subtitle>
        </Container>
        <DetailsBox>
          <COPImg src='Pilgrims.jpeg' style={{ marginBottom: '2em' }} />
          <Box sx={{ paddingLeft: '3em' }}>
            <Subtitle variant='h5' sx={{ color: '#517572' }}>
              Wedding Ceremony | 4:00pm
            </Subtitle>
            <Subtitle sx={{ color: '#517572' }}>
              Church of the Pilgrims
              <br />
              2201 P St NW <br />
              Washington, DC 20037
            </Subtitle>
            <br />
            <br />
            <Subtitle variant='h5' sx={{ color: '#517572' }}>
              Wedding Reception Following Ceremony
            </Subtitle>
            <Subtitle sx={{ color: '#517572' }}>
              <b>Drop Off Address:</b>
              <br />
              Glen Echo Park Bumper Car Pavilion
              <br />
              7300 Macarthur Blvd
              <br />
              Glen Echo, MD 20812
            </Subtitle>
            <br />
            <Subtitle sx={{ color: '#517572' }}>
              <b>Parking Address:</b>
              <br />
              Glen Echo Park Lot
              <br />
              5801 Oxford Rd
              <br />
              Glen Echo, MD 20812
            </Subtitle>
          </Box>
        </DetailsBox>
      </StyledPageContainer>
      <StyledPageContainer
        sx={{
          background: '#f6dee5',
        }}
        id='travel'
      >
        <StyledTitle variant='h1' sx={{ color: '#dc7e8b' }}>
          Travel
        </StyledTitle>

        <Box
          sx={{
            maxWidth: '1000px',
            marginLeft: 'auto',
            marginRight: 'auto',
            padding: '1em 1em',
          }}
        >
          <StyledTitle
            variant='h3'
            sx={{ color: '#dc7e8b', margin: '2em 0 1em' }}
          >
            Parking and Map
          </StyledTitle>
          <DetailsBox1>
            <HotelBox>
              <Subtitle variant='h5'>Wedding Ceremony Parking:</Subtitle>
              <ParkingImg src='Dupont-Parking.png' />
            </HotelBox>

            <HotelBox sx={{ marginTop: '2em' }}>
              <Subtitle variant='h5'>Map to Wedding Reception:</Subtitle>
              <ParkingImg src='Map-to-Reception.png' />
              <Subtitle>Free parking in Glen Echo Park lot</Subtitle>
            </HotelBox>
          </DetailsBox1>

          <StyledTitle
            variant='h3'
            sx={{ color: '#dc7e8b', margin: '2em 0 1em' }}
          >
            Hotels
          </StyledTitle>

          <Subtitle variant='body1' sx={{ textAlign: 'center' }}>
            We currently have a room block at the following hotels:
          </Subtitle>
          <DetailsBox1>
            <HotelBox
              component='a'
              sx={{
                height: '300px',
                width: '300px',
                textDecoration: 'none',
                color: '#000',
              }}
              href='https://www.hilton.com/en/attend-my-event/dcaargi-sdwb22-b34092ba-afa9-417e-96a2-4de432481376/'
            >
              <HotelImg src='HiltonGardenInnCourthouse.png' />
              <Subtitle
                sx={{ marginTop: '1em', color: '#dc7e8b' }}
                variant='h6'
              >
                Hilton Garden Inn Arlington/Courthouse Plaza
              </Subtitle>
            </HotelBox>
            <HotelBox
              component='a'
              sx={{
                height: '300px',
                width: '300px',
                textDecoration: 'none',
                color: '#000',
              }}
              href='https://www.marriott.com/event-reservations/reservation-link.mi?id=1655992635346&key=GRP&app=resvlink'
            >
              <HotelImg src='marriot.png' />
              <Subtitle
                sx={{ marginTop: '1em', color: '#dc7e8b' }}
                variant='h6'
              >
                Residence Inn Arlington Courthouse
              </Subtitle>
            </HotelBox>
          </DetailsBox1>

          <StyledTitle
            variant='h3'
            sx={{ color: '#dc7e8b', margin: '2em 0 1em' }}
          >
            Airports
          </StyledTitle>

          <DetailsBox1>
            <Box sx={{ width: { xs: '100%', lg: '300px' }, margin: '1em 1em' }}>
              <Subtitle variant='h6'>
                Washington Reagan National Airport <br /> (DCA)
              </Subtitle>
              <Subtitle>
                Reagan airport is extremely close to the city and can be
                accessed directly by the Metro train system.
              </Subtitle>
              <br />
              <Subtitle>Hub for: American</Subtitle>
            </Box>
            <Box sx={{ width: { xs: '100%', lg: '300px' }, margin: '1em 1em' }}>
              <Subtitle variant='h6'>
                Washington Dulles International Airport <br /> (IAD)
              </Subtitle>
              <Subtitle>
                Dulles airport is aproximately a 45 minute drive from the city
                and can be accessed by a bus, although we would recommend
                rideshare.
              </Subtitle>
              <br />
              <Subtitle>Hub for: United</Subtitle>
            </Box>
            <Box sx={{ width: { xs: '100%', lg: '300px' }, margin: '1em 1em' }}>
              <Subtitle variant='h6'>
                Baltimore-Washington International Airport <br /> (BWI)
              </Subtitle>
              <Subtitle>
                Baltimore-Washington airport is aproximately a 50 minute drive
                from the city can be accessed by MARC or Amtrak trains.
              </Subtitle>
              <br />
              <Subtitle>Hub for: Southwest, Spirit</Subtitle>
            </Box>
          </DetailsBox1>
        </Box>
      </StyledPageContainer>
      <StyledPageContainer
        sx={{
          background: '#c5d4e3',
        }}
        id='things-to-do'
      >
        <StyledTitle variant='h1' sx={{ color: '#4587bc' }}>
          DC Favorites
        </StyledTitle>
        <RegistryContainer
          sx={{ maxWidth: '1000px', marginLeft: 'auto', marginRight: 'auto' }}
        >
          {DcThings.map((x) => (
            <DcFunBox component='a' href={x.url}>
              <DcFunImg src={x.img} />
              <Subtitle
                sx={{ textAlign: 'center', color: '#000' }}
                variant='h6'
              >
                {x.title}
              </Subtitle>
              <Subtitle sx={{ color: '#4587bc' }}>{x.nb}</Subtitle>
            </DcFunBox>
          ))}
        </RegistryContainer>
      </StyledPageContainer>
      <StyledPageContainer
        sx={{
          background: '#f0e8cd',
        }}
        id='rsvp'
      >
        <StyledTitle
          variant='h1'
          sx={{ color: '#c39f52', paddingBottom: '30px' }}
        >
          RSVP
        </StyledTitle>
        <Subtitle variant='h5' sx={{ color: '#c39f52', textAlign: 'center' }}>
          RSVP submission has ended. See you on October 14!
        </Subtitle>
      </StyledPageContainer>

      <StyledPageContainer
        sx={{
          background: '#dfbfb4',
        }}
        id='registry'
      >
        <StyledTitle variant='h1' sx={{ color: '#a35442' }}>
          Registry
        </StyledTitle>
        <Subtitle
          variant='h5'
          sx={{ textAlign: 'center', a: { color: '#000' } }}
        >
          <a href='https://www.myregistry.com/wedding-registry/john-dockery-and-aubrey-swanson-arlington-va/3288405'>
            Click Here for Registry
          </a>
        </Subtitle>

        <RegistryContainer>
          <RegistryTile
            component='a'
            href='https://www.myregistry.com/wedding-registry/john-dockery-and-aubrey-swanson-arlington-va/3288405'
          >
            <RegistryImg src='Crate-Barrel-Logo.jpeg' />
          </RegistryTile>
          <RegistryTile
            component='a'
            href='https://www.myregistry.com/wedding-registry/john-dockery-and-aubrey-swanson-arlington-va/3288405'
          >
            <RegistryImg
              src='Target_Bullseye-Logo_Black.jpeg'
              style={{ width: '150px' }}
            />
          </RegistryTile>
          <RegistryTile
            component='a'
            href='https://www.myregistry.com/wedding-registry/john-dockery-and-aubrey-swanson-arlington-va/3288405'
          >
            <RegistryImg src='REI_Logo.jpeg' />
          </RegistryTile>
        </RegistryContainer>
      </StyledPageContainer>

      <Footer>
        <Box
          sx={{
            borderBottom: '1px solid #a35442',
            marginBottom: '25px',
          }}
        ></Box>
        <Typography variant='body1' sx={{ color: '#a35442' }}>
          Designed, illustrated, and coded from scratch with ♡ // Aubrey Swanson
          and John Dockery © 2022
        </Typography>
      </Footer>
    </ThemeProvider>
  );
};

export default App;
