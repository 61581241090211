import React from 'react';
import ReactDOM from 'react-dom';
import "typeface-roboto";
import './index.css';
import App from './App';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Seating from "./Seating";

ReactDOM.render(
    <BrowserRouter>
        <Routes>
            <Route path='/' element={<App/>}/>
            <Route path='/seating' element={<Seating/>}/>
        </Routes>
    </BrowserRouter>,
  document.getElementById('root')
);
