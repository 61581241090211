import {
    Header,
    NavButton,
    StyledBox,
    StyledButton,
    StyledMenu, StyledMenuItem,
    StyledPageContainer,
    StyledTitle,
    Subtitle
} from "./App";
import Guests from './seating.json';
import {Autocomplete, Box, Container, TextField, Typography} from "@mui/material";
import React, {useState} from "react";
import MenuIcon from "@mui/icons-material/Menu";

const guests_sorted = Guests.sort( function( a, b ) {
    return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
});

const Seating = () => {
    const [name, setName] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <Header sx={{ justifyContent: { xs: 'flex-end', md: 'space-around' } }}>
                <StyledBox sx={{ display: { xs: 'none', md: 'flex' } }}>
                    <NavButton variant='text' href='/#home'>
                        HOME
                    </NavButton>
                    <NavButton variant='text' href='/#travel'>
                        TRAVEL
                    </NavButton>
                    <NavButton variant='text' href='/#things-to-do'>
                        THINGS TO DO
                    </NavButton>
                    <NavButton variant='text' href='/#rsvp'>
                        RSVP
                    </NavButton>
                    <NavButton variant='text' href='/#registry'>
                        REGISTRY
                    </NavButton>
                    <NavButton href="/seating">
                        SEATING
                    </NavButton>
                </StyledBox>
                <StyledButton
                    sx={{
                        display: { md: 'none' },
                        marginTop: '20px',
                        marginRight: '1em',
                    }}
                    aria-controls={open ? 'demo-customized-menu' : undefined}
                    aria-haspopup='true'
                    aria-expanded={open ? 'true' : undefined}
                    disableElevation
                    onClick={handleClick}
                >
                    <MenuIcon sx={{ color: '#fff', fontSize: '56px' }} />
                </StyledButton>
                <StyledMenu
                    MenuListProps={{
                        'aria-labelledby': 'demo-customized-button',
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                >
                    <StyledMenuItem onClick={handleClose}>
                        <a href='/#home'>
                            <Typography variant='body1'>HOME</Typography>
                        </a>
                    </StyledMenuItem>
                    <StyledMenuItem onClick={handleClose}>
                        <a href='/#travel'>
                            <Typography variant='body1'>TRAVEL</Typography>
                        </a>
                    </StyledMenuItem>
                    <StyledMenuItem onClick={handleClose}>
                        <a href='/#things-to-do'>
                            <Typography variant='body1'>THINGS TO DO</Typography>
                        </a>
                    </StyledMenuItem>
                    <StyledMenuItem onClick={handleClose}>
                        <a href='/#rsvp'>
                            <Typography variant='body1'>RSVP</Typography>
                        </a>
                    </StyledMenuItem>
                    <StyledMenuItem onClick={handleClose}>
                        <a href='/#registry'>
                            <Typography variant='body1'>REGISTRY</Typography>
                        </a>
                    </StyledMenuItem>
                    <StyledMenuItem onClick={handleClose}>
                        <a href='/seating'>
                            <Typography variant='body1'>SEATING</Typography>
                        </a>
                    </StyledMenuItem>
                </StyledMenu>
            </Header>
            <StyledPageContainer sx={{
                background: '#bacfc2',
                height: '100%',
                minHeight: '100vh'
            }}>


                <StyledTitle variant='h1' sx={{ color: '#517572' }}> Seating Guide</StyledTitle>
                <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    value={name}
                    onChange={(event,newValue)=>{
                        setName(newValue);
                    }}
                    options={guests_sorted.map((x)=>{return {label: x.name, value:`${x.name}: Table ${x.table}`}})}
                    sx={{
                        width: 300,
                        margin: '2em auto'
                    }}
                    renderInput={(params) => <TextField {...params} label="Name" />}
                />
                {!name &&
                    (<Container sx={{width: '100%', justifyContent:'center'}}>
                        <Subtitle sx={{textAlign: 'center'}}>Name | Table #</Subtitle>
                        <Box sx={{
                            display: 'inline-flex',
                            flexWrap: 'wrap',
                            height: '100%',
                            marginTop: '30px',
                            justifyContent:'center'}}>
                            {guests_sorted.map(guest=><Subtitle sx={{width: '250px', margin: '5px'}}>{guest.name} | <b>Table {guest.table}</b></Subtitle>)}
                        </Box>
                    </Container>)
                }
                {name && (
                    <Box sx={{
                        background: '#517572',
                        borderRadius: '10px',
                        maxWidth: '300px',
                        padding: '1em',
                        margin: '2em auto'
                    }}>
                        <Subtitle variant='h4' sx={{textAlign: 'center'}}>{name.value}</Subtitle>
                    </Box>
                )}
            </StyledPageContainer>
        </>
    )
}

export default Seating;